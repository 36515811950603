import React, { useRef } from 'react';

function Funtor() {
  const iframeRef = useRef(null);

  const obtenerHTMLIframe = () => {
    const iframe = iframeRef.current;

    if (iframe) {
      const html = iframe.contentDocument.documentElement.outerHTML;
      console.log(html);
    }
  };

  return (
    <div>
      <iframe
        ref={iframeRef}
        src="https://com.pawkadma.eu.org/0:/"
        title="Iframe de ejemplo"
      ></iframe>
      <button onClick={obtenerHTMLIframe}>Obtener HTML del Iframe</button>
    </div>
  );
}

export default Funtor;
