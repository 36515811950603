function WebBase() {

    function boton(){
        const iframe = document.getElementById('pags');

        const iframeDoc = iframe.contentDocument;

        const prt = iframe.childNodes

        console.log(iframeDoc)
        console.log(prt)
    }

    return (<>

        <iframe src="https://com.pawkadma.eu.org/0:/" frameborder="0" id="pags" />
        <button onClick={boton}>un boton</button>

    </>)
}

export default WebBase